/* https://fonts.google.com/specimen/Josefin+Sans?selection.family=Josefin+Sans:100,400,700|Roboto:300,400,500 */
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,400,700|Roboto:300,400,500');
@import '~@styles/common';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-family: 'Josefin Sans', sans-serif;
  background-color: $navy-blue;
  /* More textures at https://www.transparenttextures.com/ */;
  // background-image: url("./assets/light-paper-fibers.png");;
}

#root {
  display: inline-block;
  width: 100%;
}

h1 {
  font-weight: 400;
  font-size: $h1-font-size;
  text-transform: uppercase;
  margin: 10px 0;
}

h2 {
  font-weight: 400;
  font-size: $h2-font-size;
}

h3 {
  font-weight: 700;
  font-size: $h3-font-size;
  text-transform: uppercase;
}

figure {
  margin: 0;
}

a {
  @include link;
}