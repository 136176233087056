@import '~@styles/configs/breakpoints';

@mixin respond-to($media...) {
  $media-list: null;
  @each $query in $media {
    $media-list: append($media-list, unquote("#{$query}"), comma);
  }
  @media #{$media-list} {
    @content
  }
}

// The most outer grid, should not be used nested
@mixin apply-grid($min: 160px, $max: 1fr) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($min, $max));
  // grid-column-gap: $gutter-size;
  max-width: $desktop-max-width;
  margin: 0 auto;
  // padding-left: $gutter-size;
  // padding-right: $gutter-size;
}

@mixin fullscreen {
  min-height: $mobile-portrait-min;
  height: 100vh;
}
