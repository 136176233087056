.container {
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.portal {
  position: relative;
  z-index: 3;
}
