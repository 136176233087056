$mobile-portrait-min: 500px;
$mobile-max: 600px;
$tablet-portrait-max: 900px;
$tablet-landscape-max: 1200px;
$desktop-max-width: 1600px;
$desktop-max: 1800px;

$mobile: #{"screen and (max-width: " + $mobile-max + ")"};
$tablet-portait: #{"screen and (min-width: " + ($mobile-max + 1) + ") and (max-width: " + $tablet-portrait-max + ")"};
$tablet-landscape: #{"screen and (min-width: " + ($tablet-portrait-max + 1) + ") and (max-width: " + $tablet-landscape-max + ")"};
$desktop: #{"screen and (min-width: " + ($tablet-landscape-max + 1) + ")"};

$gutter-size: 1em;
