@import '~@styles/mixins/responsive';
@import '~@styles/configs/breakpoints';
@import '~@styles/configs/fonts';
@import '~@styles/configs/colors';

@mixin link {
  color: $navy-blue;
}

@mixin link--white {
  color: white;
}

@mixin paper {
  background-color: $paper-white;
  border-radius: 2px;
}
